import cl from './RouletteItemSkeleton.module.scss'

interface rouletteItemSkeletonProps {
	styles?: React.CSSProperties
}

const RouletteItemSkeleton = ({ styles }: rouletteItemSkeletonProps) => {
	return (
		<div style={styles} className={cl.evWeapon}>
			<div className={cl.evWeaponInner}>
				{/* <div className={cl.icon}>
					<svg
						width='58'
						height='60'
						viewBox='0 0 58 60'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							fill-rule='evenodd'
							clip-rule='evenodd'
							d='M24.2072 0.813457C20.9045 1.76249 17.7394 3.45719 15.4 5.96535C13.4046 7.999 11.9597 10.5072 10.9276 13.1509C10.1019 15.3201 9.68906 17.5571 9.13861 19.7941C6.11114 32.6061 3.08366 45.418 0.0561801 58.1622C-0.0126262 58.6367 -0.0814325 59.179 0.262599 59.5857C0.675437 59.9247 1.22589 59.9925 1.70753 59.9925C5.28546 59.9925 8.86339 59.9925 12.3725 59.9925C13.4046 59.9925 14.5055 60.0603 15.5376 59.7891C16.2945 59.3824 16.3633 58.4333 16.5697 57.6877C18.221 50.7055 19.8724 43.7911 21.5237 36.8089C29.0236 36.8767 36.5235 36.8089 44.0234 36.8089C44.5051 36.8089 45.1243 36.8089 45.4683 36.4022C46.1564 35.8599 46.294 35.0464 46.5004 34.233C47.1885 31.3181 47.8766 28.3354 48.5646 25.4205C48.6334 24.946 48.771 24.4715 48.5646 23.997C48.2206 23.7258 47.739 23.7258 47.3261 23.7258C39.7574 23.7258 32.1199 23.7258 24.5512 23.7258C24.8953 22.2345 25.2393 20.7432 25.5833 19.2518C25.8585 18.0994 26.1338 16.947 26.8218 15.9302C27.9227 14.3033 29.9869 13.5576 31.8447 13.5576C39.0694 13.5576 46.2252 13.5576 53.4499 13.5576C54.0003 13.5576 54.6196 13.4898 54.9636 13.0831C55.4453 12.5408 55.5829 11.7951 55.7205 11.1172C56.2709 8.60909 56.8902 6.16872 57.4406 3.66056C57.7159 2.57595 58.0599 1.42355 57.9911 0.33894C57.5783 0 57.0278 0 56.4774 0C48.6334 0 40.7895 0 32.9456 0C30.1245 0 27.0971 0 24.2072 0.813457Z'
							fill='currentColor'
						/>
					</svg>
				</div> */}
			</div>
		</div>
	)
}

export default RouletteItemSkeleton
