export enum UserItemType {
  promo='promo',
  skin='skin',
  no='no'
}

export interface Prize {
	id: number
	name: string
	rarity: string
	size: number
	type: UserItemType
	percent: number
}

// @ts-ignore
export const API_URL = 'https://fonbet-cases.fun/api'
export const MAX_ITEMS = 25;


export function rand(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}