import cl from './RouletteItem.module.scss'

interface rouletteItemProps {
	id: number
	weapon_name: string
	skin_name: string
	rarity: string
	steam_image: string
	isLoser: boolean
	styles?: React.CSSProperties
}

const RouletteItem = ({
	id,
	weapon_name,
	skin_name,
	rarity,
	steam_image,
	isLoser,
	styles,
}: rouletteItemProps) => {
	return (
		<div
			className={cl.evWeapon}
			style={{
				...styles,
				opacity: isLoser ? '0.5' : '1',
				filter: isLoser ? 'blur(5px)' : 'blur(0px)',
			}}
		>
			<div className={`${cl.evWeaponInner}`} id={String(id)}>
				<div className={`${cl.evWeaponRarity} ${cl[rarity]}`}></div>

				<div className={cl.evWeaponImage}>
					<img src={steam_image} alt={weapon_name} />
				</div>

				<div className={cl.evWeaponText}>
					<span>{weapon_name}</span>
					<p>{skin_name}</p>
				</div>
			</div>
		</div>
	)
}

export default RouletteItem
