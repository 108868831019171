import React, { useEffect, useState } from 'react'
import McRoulette2 from './Components/Roulette2/McRoulette'
import { API_URL, Prize, UserItemType } from './types'
import axios from 'axios'
import { weaponAttributes } from './Components/Roulette2/roulette.classes'
import styles from './App.module.scss'
import RouletteItem from './Components/Roulette2/RouletteItem/RouletteItem'
import RouletteItemSkeleton from './Components/Roulette2/RouletteItem/RouletteItemSkeleton'

export const persentToRarity = (percent: number) => {
	if (percent <= 1) {
		return 'rare'
	} else if (percent <= 3) {
		return 'covert'
	} else if (percent <= 4) {
		return 'classified'
	} else if (percent <= 11) {
		return 'restricted'
	} else {
		return 'milspec'
	}
}

const rarityList = [
	'uncommon',
	'milspec',
	'restricted',
	'classified',
	'covert',
	'rare',
]

export const imageByPrice = (type: UserItemType, id: number) => {
	if (type === UserItemType.skin) {
		switch (id) {
			case 3:
				return '/skins/1.png'
			case 4:
				return 'https://steamcommunity-a.akamaihd.net/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgposbaqKAxf0vL3dzxG6eO6nYeDg7miYr7VlWgHscN32LyT8dmm31XgrxdtZzvzJYDGIFM2Y16D-FfvlOu9m9bi66Oq9HyE'
			case 7:
				return 'https://steamcommunity-a.akamaihd.net/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpou7uifDhz3MzRdDFO48uJmImMn-O6MbmDkz0DsJVy2-2Yp42g3wC38kBtYWqnI4_BdwE8ZVrX_AXsx-e5hoj84soOlvx0qw'
			case 8:
				return 'https://steamcommunity-a.akamaihd.net/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpoo7e1f1Jf0vL3dzFD4dmlq4yCkP_gfeuCxTMG7pFw2uiV9I-jjlHi-0dvZDygLY-dJw89NQ3QqFK3lOe9jcSi_MOeUg1XNk4'
			case 9:
				return 'https://steamcommunity-a.akamaihd.net/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpoo6m1FBRp3_bGcjhQ09-jq5WYh-TLPbTYhFRd4cJ5nqfE8dzz3Abg_hBtMWDzJ4fGdFI6YFjT-lHtlOi70Jfqvcifm3Vmvigj-z-DyA8aEmbE'
			case 10:
				return '/skins/10.png'
			case 11:
				return 'https://steamcommunity-a.akamaihd.net/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpovbSsLQJf0ebcZThQ6tCvq4OeqPXhJ6_UhG1d8fp9hfvEyoD8j1yg5UQ4Nmn1co7AJARrZVyEqVLvwb_p15G5tZzNySNgv3F3syrYlhWzhE1SLrs4lgTqAhU'
			case 12:
				return 'https://steamcommunity-a.akamaihd.net/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpot7HxfDhnwMzJemkV0966m4-PhOf7Ia_ummJW4NE_3rnHpdujjgK28kE5Y2Gid9WWdQ44YVHS-VS9wr--jJG6tJrAzCBh6D5iuyjdE47G3Q'
			case 13:
				return 'https://steamcommunity-a.akamaihd.net/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpot621FAR17P7NdTRH-t26q4SZlvD7PYTQgXtu5Mx2gv2PrdSijAWwqkVtN272JIGdJw46YVrYqVO3xLy-gJC9u5vByCBh6ygi7WGdwUKTYdRD8A'
			case 14:
				return 'https://steamcommunity-a.akamaihd.net/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DAQ1JmMR1osbaqPQJz7ODYfi9W9eO0mJWOqOf9PbDummJW4NE_3LmYo43w31Cx-xE4ZmilJoWVdFRvNQzX_1DtlLjq15G5tJnLzCFh7j5iuyjrgJbKOg'
		}
	}

	if (type === UserItemType.promo) {
		return '/freebet.svg'
	}

	return '/silver.png'
}

function App() {
	const fakePrizes = {
		data: [
			{
				id: 1,
				name: 'Ничего',
				size: 0,
				type: UserItemType.no,
				percent: 71,
				rarity: 'uncommon',
			},
			{
				id: 2,
				name: 'Фрибет 100',
				size: 100,
				type: UserItemType.promo,
				percent: 10,
				rarity: 'restricted',
			},
			{
				id: 3,
				name: 'Скин за 3 ₽',
				size: 2,
				type: UserItemType.skin,
				percent: 1,
				rarity: 'uncommon',
			},
			{
				id: 4,
				name: 'Скин за 7 ₽',
				size: 7,
				type: UserItemType.skin,
				percent: 0.04,
				rarity: 'uncommon',
			},
			{
				id: 5,
				name: 'Фрибет 300',
				size: 300,
				type: UserItemType.promo,
				percent: 5,
				rarity: 'classified',
			},
			{
				id: 7,
				name: 'Скин за 15 ₽',
				size: 15,
				type: UserItemType.skin,
				percent: 0.0008,
				rarity: 'milspec',
			},
			{
				id: 9,
				name: 'Скин за 100 ₽',
				size: 100,
				type: UserItemType.skin,
				percent: 0.00009,
				rarity: 'classified',
			},
			{
				id: 6,
				name: 'Фрибет 500',
				size: 500,
				type: UserItemType.promo,
				percent: 1,
				rarity: 'covert',
			},
			{
				id: 8,
				name: 'Скин за 30 ₽',
				size: 30,
				type: UserItemType.skin,
				percent: 0.00001,
				rarity: 'milspec',
			},
			{
				id: 10,
				name: 'Скин за 50 ₽',
				size: 50,
				type: UserItemType.skin,
				percent: 0.00001,
				rarity: 'restricted',
			},
			{
				id: 11,
				name: 'Скин за 150 ₽',
				size: 150,
				type: UserItemType.skin,
				percent: 0.00001,
				rarity: 'classified',
			},
			{
				id: 12,
				name: 'Скин за 250 ₽',
				size: 250,
				type: UserItemType.skin,
				percent: 0.00001,
				rarity: 'covert',
			},
			{
				id: 13,
				name: 'Скин за 500 ₽',
				size: 500,
				type: UserItemType.skin,
				percent: 0.00001,
				rarity: 'covert',
			},
			{
				id: 14,
				name: 'Скин за 1K ₽',
				size: 1000,
				type: UserItemType.skin,
				percent: 0.00001,
				rarity: 'rare',
			},
		],
	}

	const [prizes, setPrizes] = useState<weaponAttributes[]>([])

	const fetchPrizes = async () => {
		const response = await axios.get<Prize[]>(`${API_URL}/roulette`)
		// const response = fakePrizes

		if (typeof response.data !== 'string') {
			setPrizes(
				response.data.map(it => {
					return {
						weapon_name: it?.type,
						rarity: it?.rarity,
						percent: it?.percent,
						skin_name: it?.name,
						steam_image: imageByPrice(it?.type, it?.id),
					}
				})
			)
		}
	}

	useEffect(() => {
		fetchPrizes().then()
	}, [])

	const weaponsCount = 140
	const transitionDuration = 10

	return (
		<>
			<div className={styles.logo}>
				<img src='/logo.svg' alt='logo' />
			</div>

			<McRoulette2
				weapons={prizes}
				weaponsCount={weaponsCount}
				transitionDuration={transitionDuration}
			/>

			<section className={styles.section}>
				<h2>Призы</h2>

				<div className={styles.list}>
					{prizes.length === 0
						? [...Array(12)].map((_, index) => (
								<RouletteItemSkeleton
									key={index}
									styles={{
										width: 'auto',
										height: 'auto',
										padding: 0,
										aspectRatio: '1/1',
									}}
								/>
						  ))
						: prizes
								.slice()
								.sort(
									(a, b) =>
										rarityList.indexOf(a.rarity) - rarityList.indexOf(b.rarity)
								)
								.map((w, i) => {
									return (
										<RouletteItem
											key={i}
											id={i}
											isLoser={false}
											weapon_name={w.weapon_name}
											skin_name={w.skin_name}
											rarity={w.rarity}
											steam_image={w.steam_image}
											styles={{
												width: 'auto',
												height: 'auto',
												padding: 0,
												aspectRatio: '1/1',
											}}
										/>
									)
								})}
				</div>
			</section>
		</>
	)
}

export default App
