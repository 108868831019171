import {useEffect, useRef, useState} from 'react';
import cl from "./roulette.module.scss"
import RouletteItem from "./RouletteItem/RouletteItem";
import RouletteItemSkeleton from './RouletteItem/RouletteItemSkeleton'
import {Roulette, weaponAttributes} from "./roulette.classes";
import axios from "axios";
import {API_URL, Prize} from "../../types";
import {imageByPrice, persentToRarity} from "../../App";

interface RouletteElementParams {
  weapons: weaponAttributes[],
  weaponsCount: number,
  transitionDuration: number
}

const McRoulette2 = ({
                      weapons,
                      weaponsCount,
                      transitionDuration
                    }: RouletteElementParams) => {

  const queryParams = new URLSearchParams(window.location.search); // Parse the query parameters
  // @ts-ignore
  const tg = window.Telegram?.WebApp?.initDataUnsafe?.user?.id || queryParams.get('id') as string
  const [tgID] = useState<string>(tg);
  const [rouletteWeapons, setRouletteWeapons] = useState<weaponAttributes[]>(weapons)
  const [weaponPrizeId, setWeaponPrizeId] = useState<number>(-1)
  const [isReplay, setIsReplay] = useState<boolean>(false)
  const [isSpin, setIsSpin] = useState<boolean>(false)
  const [isSpinEnd, setIsSpinEnd] = useState<boolean>(false)
  const [winHistory, setWinHistory] = useState<weaponAttributes[]>([])

  useEffect(() => {
    setRouletteWeapons(weapons)
  }, [weapons]);


  const rouletteContainerRef = useRef<HTMLDivElement>(null)
  const weaponsRef = useRef<HTMLDivElement>(null)

  function transitionEndHandler() {
    setWinHistory(winHistory.concat(rouletteWeapons[weaponPrizeId]))
    setIsSpin(false)
    setIsSpinEnd(true)
  }

  function prepare() {
    weaponsRef.current!.style.transition = "none"
    weaponsRef.current!.style.left = "0px"
  }

  const rouletteOpen = async (): Promise<Prize> => {
    const response = await axios.post<Prize>(`${API_URL}/roulette/${tgID}`);
    if (typeof response.data === 'string') {

      throw new Error(response.data)
    }
    console.log(response.data)
    return response.data;
  };

  async function load(winner: weaponAttributes) {

    const roulette = new Roulette({
      winner,
      weapons,
      rouletteContainerRef,
      weaponsRef,
      weaponsCount: weaponsCount,
      transitionDuration: transitionDuration,
    });

    roulette.set_weapons()
    setRouletteWeapons(roulette.weapons)

    return roulette
  }


  async function play() {
    if (isReplay) {
      prepare()
    }
    setIsSpin(true)

    const prize = await rouletteOpen();
    const roulette = await load({
      weapon_name: prize?.type,
      rarity: persentToRarity(prize?.percent),
      skin_name: prize?.name,
      steam_image: imageByPrice(prize?.type, prize?.size)
    })


    setTimeout(() => {
      setIsSpin(true)
      setWeaponPrizeId(roulette.spin())
      setIsReplay(true)
    }, 1000)
  }

  const [keys, setKeys] = useState<number>(0);

  const fetchKeys = async () => {
    const response = await axios.get<number>(`${API_URL}/roulette/keys/${tgID}`)
    if (typeof response.data !== 'string') {
      setKeys(response.data);
    }
  };

  useEffect(() => {
    fetchKeys().then();
  });

  return (
		<div className={cl.rouletteWrapper}>
			<div ref={rouletteContainerRef}>
				<div className={cl.evRoulette}>
					<div className={cl.evTarget}></div>
					<div className={cl.evWeaponsWrapper}>
						<div
							ref={weaponsRef}
							className={cl.evWeapons}
							onTransitionEnd={transitionEndHandler}
						>
							{rouletteWeapons.length > 0
								? rouletteWeapons.map((w, i) => {
										return (
											<RouletteItem
												key={i}
												id={i}
												isLoser={i !== weaponPrizeId && !isSpin && isSpinEnd}
												weapon_name={w.weapon_name}
												skin_name={w.skin_name}
												rarity={w.weapon_name === 'no' ? '' : w.rarity}
												steam_image={w.steam_image}
											/>
										)
								  })
								: [...Array(12)].map((_, index) => (
										<RouletteItemSkeleton
											key={index}
											
										/>
								  ))}
						</div>
					</div>
				</div>
			</div>

			<button
				className={cl.button}
				disabled={isSpin || keys <= 0}
				onClick={play}
			>
				Открыть {keys} 🔑
			</button>
		</div>
	)
};

export default McRoulette2;